<template>
  <div v-if="currentContent">
    <component
      :is="component"
      :content="currentContent"
      :layout="layout"
      :skeleton="false"
    ></component>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "FeedContent",
  mixins: [layoutMixin],
  components: {
    PromotionElement: () => import("@/components/promotion/PromotionElement"),
    GoogleReviewElement: () =>
      import("@/components/googlereviews/GoogleReviewElement"),
    TripadvisorReviewElement: () =>
      import("@/components/tripadvisorreviews/TripadvisorReviewElement"),
    FacebookReviewElement: () =>
      import("@/components/facebookreviews/FacebookReviewElement"),
    MyBusinessElement: () =>
      import("@/components/mybusiness/MyBusinessElement"),
  },
  props: {
    type: Number,
    feedContent: Object,
  },
  data() {
    return {
      index: -1,
      timer: null,
    };
  },
  mounted() {
    this.play();
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  computed: {
    component: function() {
      switch (this.type) {
        case 7:
          return "TripadvisorReviewElement";
        case 23:
          return "PromotionElement";
        case 30:
          return "FacebookReviewElement";
        case 31:
          return "GoogleReviewElement";
        case 32:
          return "MyBusinessElement";
      }
      return null;
    },

    currentContent: function() {
      if (this.feedContent && this.feedContent.contents) {
        return this.feedContent.contents[this.index];
      }

      return null;
    },
  },
  methods: {
    play: function() {
      this.index = (this.index + 1) % this.feedContent.contents.length;
    },
  },
  watch: {
    currentContent: function() {
      this.timer = setTimeout(this.play, this.feedContent.pauseTime);
    },
  },
};
</script>
