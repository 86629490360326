import isNumber from "lodash/isNumber";

export default {
  props: {
    layout: Object,
    skeleton: Boolean,
    hasImage: Boolean,
  },
  computed: {
    position: function() {
      if (this.layout) {
        if (this.layout.positions) {
          var position;
          if (this.layout.positions.length === 1) {
            position = this.layout.positions[0];
          } else {
            position = this.layout.positions.find(
              (p) => p.withImage === this.hasImage
            );
          }
          if (position) {
            return {
              x: position.x,
              y: position.y,
              width: position.width,
              height: position.height,
            };
          }
        } else {
          // if positions does not exist, return dimension (container)
          return {
            x: this.layout.x,
            y: this.layout.y,
            width: this.layout.width,
            height: this.layout.height,
          };
        }
      }

      // return default
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    },
    x: function() {
      return this.position.x;
    },
    y: function() {
      return this.position.y;
    },
    w: function() {
      return this.position.width;
    },
    h: function() {
      return this.position.height;
    },
    u: function() {
      if (this.layout) {
        return this.layout.unit;
      }
      return "";
    },
    computedWidth: function() {
      var cw = this.w;
      if (this.u === "%") {
        cw = (window.innerWidth * this.w) / 100;

        if (this.layout.zoneWidth) {
          cw = cw * this.layout.zoneWidth;
        }
      }
      return cw;
    },
    computedHeight: function() {
      var ch = this.h;
      if (this.u === "%") {
        ch = (window.innerHeight * this.h) / 100;

        if (this.layout.zoneHeight) {
          ch = ch * this.layout.zoneHeight;
        }
      }
      return ch;
    },
    innerWidth: function() {
      var iw = this.computedWidth;
      if (this.layout.padding) {
        iw = iw - (this.layout.padding.right + this.layout.padding.left);
      }
      return iw;
    },
    innerHeight: function() {
      var ih = this.computedHeight;
      if (this.layout.padding) {
        ih = ih - (this.layout.padding.top + this.layout.padding.bottom);
      }
      return ih;
    },
    layoutStyle: function() {
      var s = {
        top: `${this.y}${this.u}`,
        left: `${this.x}${this.u}`,
        width: `${this.w}${this.u}`,
        height: `${this.h}${this.u}`,
      };

      //fix rounded
      if (this.layout.rounded) {
        //if layout is rounded, the component must be a square
        var size = Math.max(this.computedWidth, this.computedHeight);

        s.width = `${size}px`;
        s.height = `${size}px`;
      }

      if (isNumber(this.layout.zindex)) {
        s["z-index"] = this.layout.zindex;
      }

      if (this.layout.padding) {
        s["padding-top"] = this.layout.padding.top + "px";
        s["padding-right"] = this.layout.padding.right + "px";
        s["padding-bottom"] = this.layout.padding.bottom + "px";
        s["padding-left"] = this.layout.padding.left + "px";
      }
      if (this.layout.margin) {
        s["margin-top"] = this.layout.margin.top + "px";
        s["margin-right"] = this.layout.margin.right + "px";
        s["margin-bottom"] = this.layout.margin.bottom + "px";
        s["margin-left"] = this.layout.margin.left + "px";
      }

      return s;
    },
    layoutClass: function() {
      var ret = "absolute";
      if (this.skeleton) {
        ret += " border border-red-500";
      }

      if (this.layout && this.layout.rounded) {
        ret += " rounded-full";
      }

      return ret;
    },
    textStyle: function() {
      var style = {
        fontSize: this.fontSize,
        color: this.layout.color,
        whiteSpace: "pre-line",
      };

      if (this.layout.font) {
        if (this.layout.font.family) {
          style.fontFamily = this.layout.font.family;
        }
        if (this.layout.font.weight && this.layout.font.weight > 0) {
          style.fontWeight = this.layout.font.weight;
        }

        if (this.layout.font.style) {
          style.fontStyle = this.layout.font.style;
        }
      }

      return style;
    },
    textClass: function() {
      var classes = "flex flex-row";
      if (this.layout) {
        switch (this.layout.align) {
          case 0:
            classes += " justify-start text-left";
            break;
          case 1:
            classes += " justify-center text-center";
            break;
          case 2:
            classes += " justify-end text-right";
            break;
        }
        switch (this.layout.vAlign) {
          case 0:
            classes += " items-start";
            break;
          case 1:
            classes += " items-center";
            break;
          case 2:
            classes += " items-end";
            break;
        }
      }
      return classes;
    },
    borderStyle: function() {
      var ret = {};
      if (this.layout && this.layout.border) {
        var bSize = this.layout.border.size;
        var bColor = this.layout.border.color;
        ret.filter = `drop-shadow(0 -${bSize}px 0 ${bColor}) drop-shadow(0 ${bSize}px 0 ${bColor}) drop-shadow(-${bSize}px 0 0 ${bColor}) drop-shadow(${bSize}px 0 0 ${bColor})`;
      }
      return ret;
    },
    bgClass: function() {
      // theme background
      if (
        this.layout &&
        this.layout.background &&
        this.layout.background.type == 0
      ) {
        switch (this.layout.background.param) {
          // Sky = 1,
          case "1":
            return "bg-gradient-radial from-sky0 to-sky1";

          // Blue = 2,
          case "2":
            return "bg-gradient-radial from-blue0 to-blue1";

          // Brown = 3,
          case "3":
            return "bg-gradient-radial from-brown0 to-brown1";

          // Powder = 4,
          case "4":
            return "bg-powder";

          // DarkGray = 5,
          case "5":
            return "bg-gradient-radial from-darkGray0 to-darkGray1";

          // Green =  6,
          case "6":
            return "bg-gradient-radial from-green0 to-green1";

          // Ocean = 7,
          case "7":
            return "bg-gradient-radial from-ocean0 to-ocean1";

          // Orange = 8,
          case "8":
            return "bg-gradient-radial from-orange0 to-orange1";

          // Purple = 9,
          case "9":
            return "bg-gradient-radial from-purple0 to-purple1";

          // Sepia = 10,
          case "10":
            return "bg-gradient-radial from-sepia0 to-sepia1";

          // Violet = 11,
          case "11":
            return "bg-gradient-radial from-violet0 to-violet1";
        }

        return "bg-gradient-radial from-gray-500 to-gray-600";
      }

      return "";
    },
    bgStyle: function() {
      var ret = {};
      if (this.layout && this.layout.background) {
        switch (this.layout.background.type) {
          case 0:
            //not supported THEME
            break;
          case 1:
            ret.backgroundColor = this.layout.background.param;
            break;
          case 2:
            ret.backgroundImage = `url(${this.layout.background.param})`;
            ret.backgroundRepeat = "no-repeat";
            ret.backgroundSize = "cover";
            ret.backgroundPosition = "center center";
            break;
          case 3:
            // NONE
            break;
        }
      }

      return ret;
    },
  },
  methods: {
    adaptFont: function(innerHtml) {
      // Set textFitted attribute so we know this was processed.
      var innerSpan;
      var low, mid, high;

      // Add textFitted span inside this container.
      innerSpan = document.createElement("span");
      innerSpan.className = "textFitted";

      innerSpan.style["visibility"] = "hidden";
      innerSpan.style["position"] = "absolute";
      innerSpan.style["top"] = "400px";
      innerSpan.style["left"] = "1400px";

      // Inline block ensure it takes on the size of its contents, even if they are enclosed
      // in other tags like <p>
      innerSpan.style["display"] = "inline-block";
      //innerSpan.innerText = this.$el.innerText;
      //if (innerSpan.innerText.length === 0) {
      //copy html
      //innerSpan.innerHTML = this.text;
      innerSpan.innerHTML = innerHtml;
      //}

      innerSpan.style["width"] = `${this.innerWidth}px`;
      innerSpan.style["height"] = `${this.innerHeight}px`;
      innerSpan.style["overflow"] = "visible";
      // var style = window.getComputedStyle(this.$el, null);

      innerSpan.style["font-size"] = this.fontSize;
      innerSpan.style["font-family"] = this.textStyle.fontFamily;
      innerSpan.style["font-weight"] = this.textStyle.fontWeight;
      innerSpan.style["font-style"] = this.textStyle.fontStyle;

      innerSpan.style["color"] = this.layout.color;
      innerSpan.style["white-space"] = "pre-line";
      innerSpan.style["border-style"] = "solid";
      innerSpan.style["border-width"] = "1px";
      innerSpan.style["border-color"] = "#f700cd";

      document.body.appendChild(innerSpan);

      low = 10;
      high = 40;

      if (this.layout && this.layout.font) {
        low = this.layout.font.min;
        high = this.layout.font.max;
      }

      //check scale factor

      if (this.layout.applyScale) {
        var currentSize = window.innerWidth;
        var defaultSize = 1920;

        if (window.innerHeight > window.innerWidth) {
          //portrait
          currentSize = window.innerHeight;
          defaultSize = 1080;
        }
        var scaleFactor = currentSize / defaultSize;
        high = high * scaleFactor;
        low = low * scaleFactor;
      }

      // Binary search for highest best fit
      var size = low;
      while (low <= high) {
        mid = (high + low) >> 1;
        innerSpan.style.fontSize = mid + "px";
        if (
          innerSpan.scrollWidth <= this.innerWidth &&
          innerSpan.scrollHeight <= this.innerHeight
        ) {
          size = mid;
          low = mid + 1;
        } else {
          high = mid - 1;
        }
        // await injection point
      }
      // found, updating font if differs:
      this.fontSize = size + "px";

      innerSpan.remove();
    },
  },
};
